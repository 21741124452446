import React, { useContext, useState } from 'react';

import { Plus } from 'lucide-react';

import { RouterContext } from 'common/containers/RouterContainer';
import { RoadmapFilterContext } from 'common/subdomain/admin/AdminRoadmap/AdminRoadmapContext';
import { findLastActiveFilter } from 'common/subdomain/admin/AdminRoadmap/AdminRoadmapViewSettingsModal/SavedViewUtils';
import { type Option } from 'common/ui/common/select/SelectCommon';
import SingleSelectWithSearch from 'common/ui/SingleSelectWithSearch';

import type { Roadmap } from 'common/api/endpoints/roadmaps';

interface Props {
  onNewRoadmap: () => void;
  roadmap: Roadmap | null;
  roadmaps: Roadmap[];
}

const AdminRoadmapSelector = ({ onNewRoadmap, roadmap, roadmaps }: Props) => {
  const router = useContext(RouterContext);
  const { setViewDraft } = useContext(RoadmapFilterContext);

  // keep selected roadmap in local state to update UI immediately when switching roadmaps
  const [selectedRoadmap, setSelectedRoadmap] = useState<Option | undefined>(
    roadmap ? { value: roadmap._id, label: roadmap.name } : undefined
  );

  const options = roadmaps.map((roadmap) => ({
    value: roadmap._id,
    label: roadmap.name,
  }));

  const onChange = (option?: Option) => {
    if (!option) {
      return;
    }

    const roadmap = roadmaps.find((roadmap) => roadmap._id === option.value);
    if (!roadmap) {
      return;
    }

    const isSameRoadmap = roadmap._id === selectedRoadmap?.value;
    if (isSameRoadmap) {
      return;
    }

    // update local selected roadmap for immediate UI update
    setSelectedRoadmap(option);

    // reset local view draft when switching roadmaps to reset the applied view
    const lastViewedFilter = findLastActiveFilter(roadmap.settings, roadmap.savedViews);
    const lastActiveViewID = roadmap?.settings?.lastActiveFilters?.viewID || undefined;
    const view = roadmap?.savedViews.find((savedView) => lastActiveViewID === savedView._id);
    if (view) {
      setViewDraft({
        filterQuery: view.filterQuery,
        hiddenColumnIDs: view.hiddenColumnIDs,
      });
    }

    router.replace(`/admin/roadmap/${roadmap.urlName}${lastViewedFilter ?? ''}`);
  };

  return (
    <div>
      <SingleSelectWithSearch
        options={options}
        placeholder="Select roadmap"
        labelPlacement="left"
        optionsPlacement="bottom"
        onChange={onChange}
        value={selectedRoadmap}
        optionsMaxWidth="400px"
        cta={{
          option: { label: 'Create new roadmap', value: 'newRoadmap', prefixIcon: Plus },
          onAction: onNewRoadmap,
        }}
      />
    </div>
  );
};

export default AdminRoadmapSelector;
