import uniqueByKey from 'common/util/uniqueByKey';

import type { ErrorAction, LoadedAction, QueryAction } from 'common/actions/queryActionFactory';
export { getQueryKey } from 'common/actions/queryActionFactory';

export type QueryState<D, QP> = {
  cursor?: string | null;
  error: string | null;
  hasNextPage?: boolean;
  items?: D[] | null;
  lastUpdated: number | null;
  loading: boolean;
  loadingMore: boolean;
  queryKey: string;
  queryParams: QP;
};

export type QueriesState<D, QP> = {
  [queryKey: string]: QueryState<D, QP>;
} & {
  needsInvalidate?: boolean;
};

export const defaultState: QueriesState<any, any> = {};

type MongoObject = {
  _id: string;
  [key: string]: unknown;
};
const queryReducerFactory = <D extends MongoObject, QP extends Record<string, unknown>>({
  errorType,
  invalidateType,
  loadedMoreType,
  loadedType,
  requestMoreType,
  requestType,
}: {
  errorType: string;
  invalidateType: string;
  loadedMoreType: string;
  loadedType: string;
  requestMoreType: string;
  requestType: string;
}) => {
  function getUpdatedState(
    state: QueriesState<D, QP>,
    queryKey: string,
    update: Partial<QueryState<D, QP>>
  ) {
    const prevQueryState = state[queryKey];
    const newQueryState = Object.assign({}, prevQueryState, update);
    return Object.assign({}, state, {
      [queryKey]: newQueryState,
    });
  }

  return function reducer(
    state: QueriesState<D, QP> = defaultState,
    action: QueryAction<D, QP>
  ): QueriesState<D, QP> {
    switch (action.type) {
      case requestType: {
        return getUpdatedState(state, action.queryKey, {
          error: null,
          lastUpdated: action.timestamp,
          loading: true,
          loadingMore: false,
          queryKey: action.queryKey,
        });
      }

      case loadedType: {
        const loadedAction = action as LoadedAction<D, QP>;
        return getUpdatedState(state, loadedAction.queryKey, {
          cursor: loadedAction.cursor,
          error: null,
          hasNextPage: loadedAction.hasNextPage,
          items: loadedAction.items,
          lastUpdated: loadedAction.timestamp,
          loading: false,
          loadingMore: false,
          queryParams: loadedAction.queryParams,
        });
      }

      case requestMoreType: {
        return getUpdatedState(state, action.queryKey, {
          error: null,
          lastUpdated: action.timestamp,
          loading: false,
          loadingMore: true,
        });
      }

      case loadedMoreType: {
        const loadedAction = action as LoadedAction<D, QP>;
        const existingItems = state[loadedAction.queryKey]?.items ?? [];
        const newItems = loadedAction.items;
        return getUpdatedState(state, loadedAction.queryKey, {
          cursor: loadedAction.cursor,
          error: null,
          hasNextPage: loadedAction.hasNextPage,
          items: uniqueByKey([...existingItems, ...newItems], '_id'),
          lastUpdated: loadedAction.timestamp,
          loading: false,
          loadingMore: false,
        });
      }

      case errorType: {
        const errorAction = action as ErrorAction;
        return getUpdatedState(state, errorAction.queryKey, {
          error: errorAction.error,
          lastUpdated: errorAction.timestamp,
          loading: false,
          loadingMore: false,
        });
      }

      case invalidateType: {
        return Object.assign({}, state, {
          needsInvalidate: true,
        });
      }

      default:
        return state;
    }
  };
};

export default queryReducerFactory;
