import {
  ErrorType,
  InvalidateType,
  LoadedMoreType,
  LoadedType,
  type QueryParams,
  RequestMoreType,
  RequestType,
} from 'common/actions/ideaQueries';

import queryReducerFactory, { type QueriesState, type QueryState } from './queryReducerFactory';
export { defaultState, getQueryKey } from './queryReducerFactory';

import type { Idea } from 'common/api/endpoints/idea';

export type IdeaQueriesState = QueriesState<Idea, QueryParams>;
export type IdeaQueryState = QueryState<Idea, QueryParams>;

export default queryReducerFactory<Idea, QueryParams>({
  errorType: ErrorType,
  invalidateType: InvalidateType,
  loadedMoreType: LoadedMoreType,
  loadedType: LoadedType,
  requestMoreType: RequestMoreType,
  requestType: RequestType,
});
