import React, { useState } from 'react';

import classNames from 'classnames';
import { useCombobox } from 'downshift';

import Label from 'common/ui/common/Label';
import LabelWrapper from 'common/ui/common/select/LabelWrapper';
import OptionPrefix from 'common/ui/common/select/OptionPrefix';
import RightIcons from 'common/ui/common/select/RightIcons';
import { type CommonSelectProps, type Option } from 'common/ui/common/select/SelectCommon';
import SelectOptions from 'common/ui/common/select/SelectOptions';
import SupportingTexts from 'common/ui/common/select/SupportingTexts';
import { sortByGroupBy } from 'common/ui/common/select/util';
import findStringMatches from 'common/util/findStringMatches';

import 'css/components/_SelectCommon.scss';

type Props = CommonSelectProps & {
  headClassName?: string;
  onChange: (option?: Option) => void;
  onSearchChange?: (search?: string) => void;
  value?: Option;
};

const SingleSelectWithSearch = ({
  optionsMaxWidth,
  allowClear,
  disabled,
  className,
  cta,
  error,
  headClassName,
  label,
  loading,
  prefixIcon: PrefixIcon,
  onChange,
  options,
  optionsPlacement = 'bottom',
  placeholder,
  labelPlacement = 'top',
  required = false,
  supportingText,
  value,
  withBorder = true,
  onSearchChange,
}: Props) => {
  const [searchValue, setSearchValue] = useState<string | undefined>(value?.label);
  const sortedOptions = sortByGroupBy(options);
  const filteredOptions =
    searchValue && value?.label !== searchValue
      ? findStringMatches(sortedOptions, 'label', searchValue)
      : sortedOptions;
  const {
    closeMenu,
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox<Option>({
    items: cta ? [...filteredOptions, cta.option] : filteredOptions,
    selectedItem: value || null,
    itemToString: (item) => (item ? item.label : ''),
    onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
      if (cta && newSelectedItem?.value === cta?.option.value) {
        cta.onAction();
        return;
      }
      onChange(newSelectedItem ?? undefined);
      setSearchValue(newSelectedItem?.label);
    },
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEscape:
          return {
            ...changes,
            inputValue: state.selectedItem?.label,
            selectedItem: state.selectedItem,
          };
        case useCombobox.stateChangeTypes.InputFocus:
          return { ...changes, isOpen: !changes.isOpen };
        case useCombobox.stateChangeTypes.InputChange:
          if (onSearchChange) {
            onSearchChange(changes.inputValue);
          }
          setSearchValue(changes.inputValue);
          return changes;
        case useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem:
        case useCombobox.stateChangeTypes.InputBlur:
          setSearchValue(value?.label);
          return {
            ...changes,
            inputValue: changes.selectedItem?.label,
          };
        default:
          return changes;
      }
    },
  });

  return (
    <div className="dropdownV2Wrapper">
      <LabelWrapper labelPlacement={labelPlacement}>
        {label && <Label {...getLabelProps()} label={label} />}
        <div
          className={classNames('dropdownV2 singleSelect withSearch', className, {
            disabledV2: disabled,
            errorV2: error,
            withBorder,
          })}>
          <div className="dropdownWrapperV2">
            <div
              className={classNames('dropdownHeadWrapper', headClassName)}
              aria-required={required}
              aria-label="toggle menu"
              {...getToggleButtonProps({ disabled })}>
              <div className="leftWrapper">
                {PrefixIcon && <PrefixIcon className="prefixIcon" size={14} />}
                {value?.label && (
                  <OptionPrefix
                    flair={value.flair}
                    avatar={value.avatar}
                    prefixIcon={value.prefixIcon}
                    disabled={disabled}
                  />
                )}
                <input
                  className="selectInput"
                  {...getInputProps({ disabled })}
                  placeholder={value?.label || placeholder}
                  value={searchValue || ''}
                />
              </div>
              <RightIcons
                aria-label="remove selection"
                loading={loading}
                disabled={disabled}
                onClear={() => {
                  onChange();
                  setSearchValue('');
                  closeMenu();
                }}
                showClear={allowClear && !!value}
                error={error}
              />
            </div>
            <SelectOptions
              highlightedIndex={highlightedIndex}
              getItemProps={getItemProps}
              ctaOption={cta?.option}
              options={filteredOptions}
              optionsMaxWidth={optionsMaxWidth}
              selectedItems={value && [value]}
              isOpen={isOpen}
              disabled={disabled}
              getMenuProps={getMenuProps}
              optionsPlacement={optionsPlacement}
            />
          </div>
          <SupportingTexts supportingText={supportingText} error={!disabled ? error : undefined} />
        </div>
      </LabelWrapper>
    </div>
  );
};

export default SingleSelectWithSearch;
