// Deduplicate an array
const uniqueByKey = <T,>(input: T[], key: keyof T) => {
  const seen = new Set();
  return input.filter((item) => {
    const keyValue = item[key];
    if (seen.has(keyValue)) {
      return false;
    }

    seen.add(keyValue);
    return true;
  });
};

export default uniqueByKey;
