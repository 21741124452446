import type { RolePermissionName } from 'common/api/endpoints/companies';
import type { Feature, Integration } from 'common/constants/billing';

export const featureNames: Record<Feature, string> = {
  adminRoles: 'Admin Roles',
  advancedAnalytics: 'Advanced Analytics',
  autoResponse: 'Smart Replies',
  categories: 'Categories',
  changelogEmailSubscriptions: 'Changelog Email Subscriptions',
  commentSearch: 'Comment Searching',
  customAccess: 'Custom Board Access',
  customDomain: 'Custom Domains',
  customEmailDomain: 'Custom Email Domains',
  customPostFields: 'Custom Post Fields',
  customRoles: 'Custom Roles',
  customStatuses: 'Custom Statuses',
  deduplication: 'Deduplication',
  disableUserSubmissions: 'Disable User Submissions',
  exportComments: 'Export Comments',
  fileAttachments: 'File Attachments',
  internalComments: 'Internal Comments',
  knowledgeHub: 'Knowledge Hub',
  liveSupport: 'Priority Support',
  moderationTools: 'Moderation Tools',
  postAutomation: 'Post Automation',
  postETAs: 'Post ETAs',
  postOwners: 'Post Owners',
  prioritizationRoadmap: 'Roadmap Prioritization',
  privateAuthors: 'Private Authors',
  privateBoards: 'Private Boards',
  privateChangelog: 'Private Changelog',
  privateComments: 'Private Comments',
  privateVotes: 'Private Votes',
  removeBranding: 'Remove Branding',
  reviewSources: 'Review Sources',
  shareRoadmaps: 'Share Roadmaps',
  summarizeComments: 'Summarize Comments',
  supportExtraction: 'Support Extraction',
  tags: 'Tags',
  translateContent: 'Translate Content',
  unlistedBoards: 'Unlisted Boards',
  userProfiles: 'User Profiles',
  userSegmentation: 'User Segmentation',
  voteWeights: 'Vote weights',
};

export const integrationNames: Record<Integration, string> = {
  api: 'API',
  asana: 'Asana',
  azureAD: 'Azure AD',
  azureDevops: 'Azure DevOps',
  browserExtension: 'Browser Extension',
  clickup: 'Clickup',
  discord: 'Discord',
  freshdesk: 'Freshdesk',
  gong: 'Gong',
  gsuite: 'Google Workspace',
  helpscout: 'Help Scout',
  hubspot: 'HubSpot',
  intercom: 'Intercom',
  jira: 'Jira',
  linear: 'Linear',
  microsoftTeams: 'Microsoft Teams',
  oidc: 'OIDC',
  okta: 'Okta',
  oneLogin: 'OneLogin',
  salesforce: 'Salesforce',
  segment: 'Segment',
  slack: 'Slack',
  tldv: 'TLDV',
  webhooks: 'Webhooks',
  zapier: 'Zapier',
  zendesk: 'Zendesk',
  zoom: 'Zoom',
};

export const permissionNames: Record<RolePermissionName, string> = {
  changePostETA: 'Change post ETA',
  changePostOwner: 'Change post owner',
  changePostStatus: 'Change post status',
  changePostTags: 'Change post tags',
  createPublicComment: 'Create public comments',
  customizeBoards: 'Customize "Create Post" forms',
  deleteComments: 'Delete comments',
  deleteInstance: 'Delete company instance',
  deletePosts: 'Delete posts',
  deleteUsers: 'Delete users',
  exportData: 'Export data',
  importPosts: 'Import posts',
  manageAPIKey: 'Manage API key',
  manageAuthSettings: 'Manage authentication settings',
  manageBilling: 'Manage billing',
  manageBoards: 'Create, delete & update boards',
  manageBoardPrivacy: 'Manage board privacy',
  manageCategories: 'Create/delete categories',
  manageChangelog: 'Manage changelog',
  manageChangelogPrivacy: 'Manage changelog privacy',
  manageCompanyProfile: 'Manage company profile',
  manageCustomDomains: 'Manage custom domains',
  manageCustomPostFields: 'Manage post fields',
  manageEmailSettings: 'Manage email settings',
  manageQueue: 'Manage inbox settings',
  manageIntegrations: 'Manage integrations',
  managePostAutomation: 'Manage post automation',
  manageRoadmap: 'Manage roadmaps',
  manageSSOKey: 'Manage SSO key',
  manageStatuses: 'Create/delete post statuses',
  manageTags: 'Create/delete post tags',
  manageTeammates: 'Manage teammates',
  manageUserSegmentation: 'Manage user segments',
  manageWebhooks: 'Manage webhooks',
  mergePosts: 'Merge/unmerge posts',
  pinComments: 'Pin comments',
  summarizeComments: 'Summarize comments',
  viewUserDetails: 'View user details',
  voteOnBehalf: 'Vote on behalf of others',
};

export const syncJobs = [
  // Autopilot client-data syncs
  'FreshdeskTicketSyncManager',
  'GongCallSyncManager',
  'HelpscoutConversationSyncManager',
  'IntercomConversationSyncManager',
  'ReviewSourcesSyncManager',
  'TLDVMeetingSyncManager',
  'ZendeskTicketSyncManager',
  'ZoomMeetingSyncManager',

  // Autopilot internal
  'AutoResponseSyncManager',
  'CompanyAICreditResetManager',
  'FeatureSyncManager',
  'QueueItemSyncManager',

  // Client-data syncs
  'AsanaWebhookSyncManager',
  'ClickupDataSyncManager',
  'IntercomSyncManager',
  'OpportunitySyncManager',
  'SalesforceSyncManager',

  // Internal data jobs
  'CannyHubSpotSyncManager',
  'ElasticSyncManager',
  'FreePlanExpirationManager',
  'LastThirtyDayVoteCountSyncManager',
  'RecalculateMRRManager',
  'SimplesatSyncManager',
  'SSLCertRenewalManager',
  'UserLookupsSyncManager',

  // Notification jobs
  'ChurnReportManager',
  'DelinquentNotificationManager',
  'TeamReportsManager',
  'UserReportsManager',

  // Producers
  'DemoProducer',
] as const;
