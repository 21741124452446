import DataV2, { type DataQuery } from 'common/DataV2';

import type { QueryState } from 'common/reducers/queryReducerFactory';
import type { Dispatch, GetState } from 'redux-connect';

export type QueryResponse<D> = {
  cursor: string | null;
  hasNextPage: boolean;
  items: D[];
};

export type BaseAction = Action & {
  queryKey: string;
  timestamp: number;
  type: string;
};
export type ErrorAction = BaseAction & {
  error: string;
};
export type LoadedAction<D, QP> = BaseAction &
  QueryResponse<D> & {
    queryParams: QP;
  };
export type QueryAction<D, QP> = BaseAction | ErrorAction | LoadedAction<D, QP>;

export function getQueryKey(queryParams: Record<string, unknown>) {
  return JSON.stringify(queryParams);
}

const requestActionFactory = <D extends object, QP extends Record<string, unknown>>(
  key: string,
  dataQuery: DataQuery
) => {
  const ErrorType = `canny/${key}/error`;
  const InvalidateType = `canny/${key}/invalidate`;
  const LoadedMoreType = `canny/${key}/loadedMore`;
  const LoadedType = `canny/${key}/loaded`;
  const RequestMoreType = `canny/${key}/requestMore`;
  const RequestType = `canny/${key}/request`;

  // actions

  function errorAction(queryKey: string, error: string) {
    return {
      error,
      queryKey,
      timestamp: Date.now(),
      type: ErrorType,
    };
  }

  function invalidateAction() {
    return {
      timestamp: Date.now(),
      type: InvalidateType,
    };
  }

  function loadedAction(
    queryKey: string,
    queryParams: QP,
    { cursor, hasNextPage, items }: QueryResponse<D>
  ): LoadedAction<D, QP> {
    return {
      cursor,
      hasNextPage,
      items,
      queryKey,
      queryParams,
      timestamp: Date.now(),
      type: LoadedType,
    };
  }

  function loadedMoreAction(
    queryKey: string,
    queryParams: QP,
    { cursor, hasNextPage, items }: QueryResponse<D>
  ): LoadedAction<D, QP> {
    return {
      cursor,
      hasNextPage,
      items,
      queryKey,
      queryParams,
      timestamp: Date.now(),
      type: LoadedMoreType,
    };
  }

  function requestAction(queryKey: string): BaseAction {
    return {
      queryKey,
      timestamp: Date.now(),
      type: RequestType,
    };
  }

  function requestMoreAction(queryKey: string): BaseAction {
    return {
      queryKey,
      timestamp: Date.now(),
      type: RequestMoreType,
    };
  }

  // query

  function fetchQuery(queryKey: string, queryParams: QP, cursor?: string | null) {
    return async (dispatch: Dispatch, getState: GetState) => {
      if (cursor) {
        dispatch(requestMoreAction(queryKey));
      } else {
        dispatch(requestAction(queryKey));
      }

      const cookies = getState().cookies;
      try {
        const response: QueryResponse<D> = await DataV2.fetch(
          dataQuery,
          {
            ...queryParams,
            ...(cursor && { cursor }),
          },
          cookies
        );
        if (cursor) {
          return dispatch(loadedMoreAction(queryKey, queryParams, response));
        } else {
          return dispatch(loadedAction(queryKey, queryParams, response));
        }
      } catch (error: any) {
        return dispatch(errorAction(queryKey, error));
      }
    };
  }

  // action creators

  function loadQuery(queryParams: QP) {
    return (dispatch: Dispatch, getState: GetState): void | Promise<void> => {
      const queryKey = getQueryKey(queryParams);
      const state = getState();

      const needsInvalidate = state[key]?.needsInvalidate;
      const query = state[key]?.[queryKey];

      if (needsInvalidate || !query) {
        return dispatch(fetchQuery(queryKey, queryParams));
      }
    };
  }

  function loadMore(query: QueryState<D, QP>) {
    return (dispatch: Dispatch) => {
      const { cursor, hasNextPage, queryParams, queryKey } = query;

      if (!hasNextPage) {
        throw new Error('Should not call loadMore without `hasNextPage: true`');
      }

      return dispatch(fetchQuery(queryKey, queryParams, cursor));
    };
  }

  function invalidateQueries() {
    return (dispatch: Dispatch) => {
      return dispatch(invalidateAction());
    };
  }

  return {
    // Action Creators
    loadQuery,
    loadMore,
    invalidateQueries,

    // Types
    ErrorType,
    InvalidateType,
    LoadedMoreType,
    LoadedType,
    RequestMoreType,
    RequestType,
  };
};

export default requestActionFactory;
