import React, { useContext } from 'react';

import { type LocalFile } from 'common/constants/files';
import { OpenModalContext } from 'common/containers/ModalContainer';
import FormImagesItem from 'common/image/FormImagesItem';
import PhotoViewer from 'common/PhotoViewer';

import 'css/components/image/_FormImages.scss';

type Props = {
  allowRemove: boolean;
  images: LocalFile[];
  onImageRemoved?: (image: LocalFile) => void;
};

const FormImages = ({ allowRemove, images, onImageRemoved }: Props) => {
  const openModal = useContext(OpenModalContext);

  const onImageClicked = (image: LocalFile) => {
    const imageURLs = images
      .reverse()
      .map((image) => image.url)
      .filter(Boolean);
    const index = imageURLs.indexOf(image.url);
    openModal(PhotoViewer, { imageURLs: imageURLs, defaultIndex: index });
  };

  const renderedImages = images.map((image) => {
    return (
      <FormImagesItem
        allowRemove={allowRemove}
        key={image.uniqueID}
        image={image}
        onImageClicked={onImageClicked}
        onImageRemoved={onImageRemoved}
      />
    );
  });

  return <div className="formImages">{renderedImages}</div>;
};

export default FormImages;
